<template>
  <section
    v-loading="loading"
    :element-loading-text="loadingText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- <el-card shadow="never" style="margin-top: 5px; position: relative"> -->
    <dialog-component
      :visible="previewEditorContentModal"
      title="Editor Preview"
      @before-close="closeFillingEditorFields"
      :modalAppendToBody="false"
      :fullscreen="true"
      :show-close="false"
      class="custom-dialog"
      width="getIsMobile ? '100%' : '100%'"
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="d-flex">
        <span style="white-space: nowrap; width: 1500px; margin-top: 2px">
          Hello
          <span style="font-weight: bold">
            {{ getCurrentActiveUserAndDocumentsUsers[0] }}
          </span>
          <span
            v-if="
              progressPercentage !== 100 &&
              isCurrentApproverWithNoFields &&
              !isCurrentApproverWithNoFields.length
            "
          >
            Some fields are still unfilled. Please fill them to complete the
            document process.
          </span>
          <span
            v-if="
              progressPercentage !== 100 &&
              isCurrentApproverWithNoFields &&
              isCurrentApproverWithNoFields.length
            "
          >
            there are no fields to complete in this document. You may now
            proceed to either 'Approve' or 'Decline' the document based on your
            review.
          </span>
          <span v-if="!checkApproveAccess() && progressPercentage === 100">
            You have completed all required fields. To finish the document
            please click "Finish".
          </span>
          <span v-if="checkApproveAccess() && progressPercentage === 100">
            You have completed all required fields.To finish the document please
            click "Approve".
          </span>
        </span>
      </div>
      <hr />
      <el-row class="sidemenu">
        <el-col :span="24">
          <div v-html="this.previewEditorContent"></div>
        </el-col>
      </el-row>
      <span slot="footer">
        <el-button
          v-if="
            isCurrentApproverWithNoFields &&
            !isCurrentApproverWithNoFields.length
          "
          @click="showFillingModal"
          type="primary"
          size="mini"
          >Edit Data</el-button
        >
        <el-button
          @click="emitFinishEvent"
          type="danger"
          size="mini"
          v-loading="loading"
          :element-loading-text="'Document Finishing Is In Process...'"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          v-if="
            (reqCount && !reqCount.length && !checkApproveAccess()) ||
            (progressPercentage === 100 &&
              !checkApproveAccess() &&
              showFinishAndApprove)
          "
          >Finish</el-button
        >
        <el-button
          @click="emitApproveEvent"
          type="success"
          size="mini"
          v-loading="loading"
          :element-loading-text="'Document Approval Is In Process...'"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
          v-if="
            (reqCount && !reqCount.length && checkApproveAccess()) ||
            ((progressPercentage === 100 || (reqCount && !reqCount.length)) &&
              checkApproveAccess() &&
              showFinishAndApprove)
          "
          >Approve</el-button
        >
        <el-button
          type="danger"
          size="mini"
          @click="rejectUserDocumentModal = true"
          v-if="
            (reqCount && !reqCount.length && checkRejectAccess()) ||
            ((progressPercentage === 100 || (reqCount && !reqCount.length)) &&
              checkRejectAccess() &&
              showFinishAndApprove)
          "
        >
          <span>Decline</span>
        </el-button>
      </span>
    </dialog-component>
    <dialog-component
      :destroy-on-close="true"
      :visible="rejectUserDocumentModal"
      title="Reason for Decline"
    >
      <el-input
        type="textarea"
        :rows="2"
        placeholder="Reason for Decline"
        v-model="reason_for_reject"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="rejectDoc"
          type="primary"
          :disabled="!reason_for_reject.length"
          >Submit</el-button
        >
        <el-button @click="rejectUserDocumentModal = false">Cancel</el-button>
      </span>
    </dialog-component>
    <dialog-component
      :visible="fillingEditorFields"
      title="Fill Data"
      @before-close="closeFillingEditorFields"
      :fullscreen="true"
      :show-close="false"
      :modalAppendToBody="false"
      class="custom-dialog"
      v-loading="loading"
      :element-loading-text="loadingText"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :width="getIsMobile ? '100%' : '100%'"
    >
      <div>
        <el-form label-position="top">
          <el-input
            :style="getIsMobile ? 'margin-left:10px;margin-right:10px' : ''"
            placeholder="Search by label..."
            v-model="searchedLabel"
            clearable
            class="mt-1 mr-1"
            style="width: 100%"
          ></el-input>
          <hr />
          <el-row class="sidemenu">
            <el-col :span="24">
              <div
                :style="getIsMobile ? 'margin-left:10px;margin-right:10px' : ''"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <span v-if="!getIsMobile" style="white-space: nowrap"
                  >Hello
                  <p style="display: inline; font-weight: bold">
                    {{ getCurrentActiveUserAndDocumentsUsers[0] }}
                  </p>
                  Some fields are still unfilled. Please fill them to complete
                  the document process.</span
                >
                <span v-else-if="getIsMobile"
                  >Hello
                  <p style="display: inline; font-weight: bold">
                    {{ getCurrentActiveUserAndDocumentsUsers[0] }}
                  </p>
                  Please fill all the fields to complete the document
                  process.</span
                >
                <el-progress
                  v-if="progressPercentage === 100 && !searchedLabel.length"
                  type="circle"
                  status="success"
                  :width="getIsMobile ? 60 : 85"
                  style="margin-top: 5px; margin-left: 2%"
                  :stroke-width="6"
                  :format="getFormat"
                  :percentage="progressPercentage"
                >
                </el-progress>
                <el-progress
                  v-if="progressPercentage !== 100 && !searchedLabel.length"
                  type="circle"
                  :width="getIsMobile ? 60 : 85"
                  style="margin-top: 5px; margin-left: 2%"
                  :stroke-width="6"
                  :format="getFormat"
                  :percentage="progressPercentage"
                >
                </el-progress>
                <el-button @click="showCkPreview">Preview</el-button>
              </div>
              <hr />
            </el-col>
            <el-col
              :span="getIsMobile ? 24 : 8"
              v-for="(item, index) of editorFields"
              :key="index"
            >
              <el-form-item
                :label="item.label"
                style="font-weight: bold"
                :style="getIsMobile ? 'margin-left:10px' : ''"
                v-if="checkIsCurrentUser(item)"
              >
                <el-input
                  @blur="checkReqFieldsFilled(item)"
                  class="same-width"
                  v-if="isTextInputType(item) && checkIsCurrentUser(item)"
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Enter Value'
                  "
                  v-model="form[item.key]"
                  :class="{ 'input-error': isEmpty(item) }"
                ></el-input>
                <el-input
                  @blur="checkReqFieldsFilled"
                  class="same-width"
                  v-if="getFieldTypeIsEmail(item) && checkIsCurrentUser(item)"
                  v-model="form[item.key]"
                  :disabled="true"
                >
                </el-input>
                <el-input
                  @blur="checkReqFieldsFilled"
                  class="same-width"
                  v-if="
                    (item.input_type === 'HEADING' ||
                      item.input_type === 'PARAGRAPH') &&
                    checkIsCurrentUser(item)
                  "
                  v-model="form[item.key]"
                  :class="{ 'input-error': isEmpty(item) }"
                ></el-input>
                <el-button
                  @blur="checkReqFieldsFilled"
                  v-if="
                    item.input_type == 'SIGNATURE' &&
                    !form[item.key] &&
                    checkIsCurrentUser(item)
                  "
                  @click="captureSignature(item, index)"
                  :class="{ 'input-error': isEmpty(item) }"
                >
                  {{ item.label }}
                  <i class="el-icon-edit"></i>
                </el-button>
                <div
                  class="d-flex"
                  v-if="
                    item.input_type == 'SIGNATURE' &&
                    form[item.key] &&
                    checkIsCurrentUser(item)
                  "
                >
                  <img width="200px" height="50px" :src="form[item.key]" />
                  <el-tooltip content="Edit Signature">
                    <el-button type="primary" size="mini">
                      <i
                        class="el-icon-edit"
                        @click="captureSignature(item, index)"
                      ></i> </el-button
                  ></el-tooltip>
                </div>
                <currency-input
                  @blur="checkReqFieldsFilled"
                  v-if="
                    item.input_type === 'CURRENCY' && checkIsCurrentUser(item)
                  "
                  v-model="form[item.key]"
                  style="height: 24px; width: 70%"
                  :currency="
                    item.validations && item.validations.currency
                      ? item.validations.currency
                      : 'USD'
                  "
                  :locale="
                    item.validations && item.validations.locale
                      ? item.validations.locale
                      : 'en-US'
                  "
                  class="field-textarea currency-input"
                  :class="{ 'input-error': isEmpty(item) }"
                />
                <el-input
                  class="same-width"
                  @blur="checkReqFieldsFilled"
                  type="number"
                  v-if="
                    (item.input_type === 'NUMBER' ||
                      item.input_type === 'PHONE_COUNTRY_CODE') &&
                    checkIsCurrentUser(item)
                  "
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Enter Value'
                  "
                  v-model="form[item.key]"
                  :class="{ 'input-error': isEmpty(item) }"
                ></el-input>
                <el-select
                  class="same-width"
                  @blur="checkReqFieldsFilled"
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Please Select'
                  "
                  v-if="item.input_type === 'LIST' && checkIsCurrentUser(item)"
                  :default-first-option="true"
                  v-model="form[item.key]"
                  :class="{ 'input-error': isEmpty(item) }"
                >
                  <el-option
                    v-for="option of item.list_data"
                    :value="option.value"
                    :key="option.value"
                    :label="option.name"
                  ></el-option>
                </el-select>

                <el-select
                  class="same-width"
                  @blur="checkReqFieldsFilled"
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type === 'SELECT' && checkIsCurrentUser(item)
                  "
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Enter Value'
                  "
                  v-model="form[item.key]"
                >
                  <el-option
                    v-for="op in item.options"
                    :key="op"
                    :label="op"
                    :value="op"
                  ></el-option>
                </el-select>
                <el-checkbox
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type === 'CHECKBOX' && checkIsCurrentUser(item)
                  "
                  v-model="form[item.key]"
                  @blur="checkReqFieldsFilled"
                ></el-checkbox>
                <el-checkbox-group
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type === 'CHECKBOX_GROUP' &&
                    checkIsCurrentUser(item)
                  "
                  v-model="form[item.key]"
                  @blur="checkReqFieldsFilled"
                  :max="getMaxValue(item)"
                >
                  <el-checkbox
                    v-for="option in item.options"
                    :label="option"
                    :key="option"
                  >
                    {{ option }}
                  </el-checkbox>
                </el-checkbox-group>
                <el-radio-group
                  v-if="
                    item.input_type === 'RADIO_BUTTON_GROUP' &&
                    checkIsCurrentUser(item)
                  "
                  v-model="form[item.key]"
                >
                  <el-radio
                    v-for="option in item.options || []"
                    :label="option"
                    :key="option"
                  >
                    {{ option }}
                  </el-radio>
                </el-radio-group>
                <el-select
                  class="same-width"
                  multiple
                  @blur="checkReqFieldsFilled"
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type === 'MULTI_SELECT' &&
                    checkIsCurrentUser(item)
                  "
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Enter Value'
                  "
                  v-model="form[item.key]"
                >
                  <el-option
                    v-for="op in item.options"
                    :key="op"
                    :label="op"
                    :value="op"
                  ></el-option>
                </el-select>
                <el-select
                  class="same-width"
                  @blur="checkReqFieldsFilled(item, index)"
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type === 'WEEKDAYS' &&
                    (item.allow_multiple === 'false' ||
                      item.allow_multiple === false) &&
                    checkIsCurrentUser(item)
                  "
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Please select'
                  "
                  :clearable="true"
                  :filterable="true"
                  v-model="form[item.key]"
                  collapse-tags
                >
                  <el-option
                    v-for="(option, index) of weekdays_options"
                    :value="option.value"
                    :label="option.name"
                    :key="index"
                  ></el-option>
                </el-select>
                <el-select
                  class="same-width"
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type === 'WEEKDAYS' &&
                    (item.allow_multiple === 'true' ||
                      item.allow_multiple === true) &&
                    checkIsCurrentUser(item)
                  "
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Please select'
                  "
                  :clearable="true"
                  @blur="checkReqFieldsFilled"
                  :filterable="true"
                  v-model="form[item.key]"
                  multiple
                  collapse-tags
                >
                  <el-option
                    v-for="(option, index) of weekdays_options"
                    :value="option.value"
                    :label="option.name"
                    :key="index"
                  ></el-option>
                </el-select>
                <el-date-picker
                  class="same-width"
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type == 'DATE_RANGE' && checkIsCurrentUser(item)
                  "
                  :format="'MM-dd-yyyy '"
                  range-separator="To"
                  start-placeholder="Start date"
                  @blur="checkReqFieldsFilled"
                  end-placeholder="End date"
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Enter Value'
                  "
                  type="daterange"
                  v-model="form[item.key]"
                ></el-date-picker>
                <el-date-picker
                  class="same-width"
                  :disabled="true"
                  @blur="checkReqFieldsFilled"
                  v-if="
                    item.input_type == 'DATE_SIGNED' && checkIsCurrentUser(item)
                  "
                  :format="'MM-dd-yyyy'"
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Enter Value'
                  "
                  type="date"
                  v-model="form[item.key]"
                ></el-date-picker>
                <el-date-picker
                  class="same-width"
                  @blur="checkReqFieldsFilled"
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="item.input_type == 'DATE' && checkIsCurrentUser(item)"
                  :format="'MM-dd-yyyy'"
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Enter Value'
                  "
                  type="date"
                  v-model="form[item.key]"
                ></el-date-picker>
                <el-time-picker
                  class="same-width"
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type == 'TIME_RANGE' && checkIsCurrentUser(item)
                  "
                  range-separator="To"
                  start-placeholder="Start time"
                  @blur="checkReqFieldsFilled"
                  format="HH:mm:ss"
                  value-format="HH:mm:ss"
                  end-placeholder="End time"
                  @focus="handleTimeRange(item)"
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Enter Value'
                  "
                  is-range
                  v-model="form[item.key]"
                ></el-time-picker>
                <el-time-picker
                  class="same-width"
                  @blur="checkReqFieldsFilled"
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type == 'TIME' &&
                    !item.is_fixed &&
                    checkIsCurrentUser(item)
                  "
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Enter Value'
                  "
                  type="time"
                  format="HH:mm:ss"
                  value-format="HH:mm:ss"
                  v-model="form[item.key]"
                ></el-time-picker>
                <el-time-select
                  v-if="
                    item.input_type == 'TIME' &&
                    item.is_fixed &&
                    checkIsCurrentUser(item)
                  "
                  :picker-options="getTimeProperties(item)"
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Select Fixed Time'
                  "
                  v-model="form[item.key]"
                  prefix-icon=""
                  v-bind:class="{
                    'input-error': isEmpty(item),
                    'time-picker-item': true,
                    'smaller-than': true,
                  }"
                  format="HH:mm:ss"
                >
                </el-time-select>
                <el-date-picker
                  class="same-width"
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type == 'DATE_TIME_RANGE' &&
                    checkIsCurrentUser(item)
                  "
                  :format="'MM-dd-yyyy HH:mm:ss'"
                  range-separator="To"
                  @blur="checkReqFieldsFilled"
                  start-placeholder="Start date"
                  end-placeholder="End date"
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Enter Value'
                  "
                  type="datetimerange"
                  v-model="form[item.key]"
                ></el-date-picker>
                <el-date-picker
                  class="same-width"
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type == 'DATE_TIME' && checkIsCurrentUser(item)
                  "
                  @blur="checkReqFieldsFilled"
                  :format="'MM-dd-yyyy HH:mm:ss'"
                  :placeholder="
                    item.placeholder ? item.placeholder : 'Enter Value'
                  "
                  type="datetime"
                  v-model="form[item.key]"
                ></el-date-picker>
                <el-button
                  :class="{ 'input-error': isEmpty(item) }"
                  v-if="
                    item.input_type === 'IMAGE' &&
                    !form[item.key] &&
                    checkIsCurrentUser(item)
                  "
                  @click="uploadImageOuterVisible(index)"
                  >Upload Image <i class="el-icon-upload"></i
                ></el-button>
                <div
                  v-if="
                    item.input_type == 'IMAGE' &&
                    form[item.key] &&
                    form[item.key].length
                  "
                >
                  <img
                    :style="`width: 90px;height:90px`"
                    @click="uploadImageOuterVisible(index)"
                    :src="form[item.key]"
                  />
                  <i
                    @click="uploadImageOuterVisible(index)"
                    class="el-icon-upload"
                  ></i>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <span slot="footer">
        <span
          class="alertSty"
          v-if="
            reqCount && !reqCount.length && nonReqCount && nonReqCount.length
          "
        >
          <img src="../../assets/img/icons/Hand.svg" class="imgSty" />
          There are {{ nonReqCount.length }} optional field(s) left! You can
          fill them in or continue to finish the document.
        </span>
        <span class="alertStyS" v-if="progressPercentage === 100">
          <img src="../../assets/img/icons/Hand.svg" class="imgSty" />
          <!-- <img
              alt="Completed"
              src="@/assets/img/workflow-sent-success.gif"
              width="50px"
              height="50px"
            /> -->
          Congratulations! You have successfully completed filling all the
          fields.
        </span>
        <div class="buttonGroup">
          <el-button @click="showCkPreview">Close</el-button>
          <el-button type="primary" @click="mapCkEditorDataUserSide()"
            >Save Data</el-button
          >
        </div>
      </span>
    </dialog-component>
    <dialog-component
      :visible="captureSignatureDialog"
      title="Authorize Signature"
      :show-close="false"
      class="outersize-confirm-type-three authorize-signature-popup"
      width="getIsMobile ? '100%' : '100%'"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-loading="signLoading"
    >
      <ESignature
        v-if="captureSignatureDialog"
        :purpose="'COMPANY_DOCUMENT'"
        :isSecureSign="secureEsign"
        v-on:onVerfiedSignature="updateDocumentSignature"
        v-on:cancelESign="captureSignatureDialog = false"
      ></ESignature>
    </dialog-component>
    <dialog-component
      :title="'Update picture'"
      :visible="outerVisible"
      width="getIsMobile ? '100%' : '30%'"
    >
      <vue-anka-cropper
        class="upload-file"
        :options="{
          aspectRatio: false,
          closeOnSave: true,
          cropArea: 'box',
          croppedHeight: 1000,
          croppedWidth: 1000,
          cropperHeight: false,
          dropareaMessage: ' Drag & Drop  file here or use the button below.',
          frameLineDash: [1, 1000],
          frameStrokeColor: 'rgba(255, 255, 255, 0.8)',
          handleFillColor: 'rgba(255, 255, 255, 0.2)',
          handleHoverFillColor: 'rgba(255, 255, 255, 0.4)',
          handleHoverStrokeColor: 'rgba(255, 255, 255, 1)',
          handleSize: 5,
          handleStrokeColor: 'rgba(255, 255, 255, 0.8)',
          layoutBreakpoint: 40,
          maxCropperHeight: 1000,
          maxFileSize: 8000000,
          overlayFill: 'rgba(0, 0, 0, 0.5)',
          previewOnDrag: true,
          previewQuality: 0.65,
          resultQuality: 0.8,
          resultMimeType: 'image/jpeg',
          resultMimeType: 'image/png',
          resultMimeType: 'image/jpg',
          resultMimeType: 'image/gif',
          selectButtonLabel: 'Select File Supported : JPG, PNG, JPEG, GIF',

          showPreview: true,
          skin: 'light',
          uploadData: {},
          uploadTo: false,
        }"
        name="logo"
        @cropper-error="fileError"
        @cropper-file-selected="insertImage"
        @cropper-preview="setImageData"
        @cropper-saved="setImageData"
        v-loading="upLoadEffect"
        accept="image/jpeg,image/png,image/jpg,image/gif"
      ></vue-anka-cropper>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            outerVisible = false;
            resetImgData();
          "
          >Cancel</el-button
        >
        <el-button type="primary" @click="closeImage">Save</el-button>
      </span>
    </dialog-component>
  </section>
</template>
<script>
import DocumentsHelper from "@/mixins/CompanyDocumentsHelper";
import { postAPICall } from "../../helpers/httpHelper";
import FormbuilderHelper from "@/mixins/FormbuilderHelper.js";
import EntityHelper from "@/mixins/EntitiesHelper";
import vueAnkaCropper from "vue-anka-cropper";
import "vue-anka-cropper/dist/VueAnkaCropper.css";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  mixins: [DocumentsHelper, FormbuilderHelper, EntityHelper],
  async mounted() {
    this.loading = true;
    this.loadingText = "Fetching Data...";
    this.employeeDoc = this.employeeDocument;
    this.docUsers = this.documentUsers;
    this.conId = this.configDocId;
    this.empId = this.employeeDocId;
    await this.fetchConfigurableDocument();
    this.fillingUserFields = true;
    this.checkIsApproverAndHasNoFields();
    this.loading = false;
  },
  components: {
    vueAnkaCropper,
    ESignature: () => import("@/components/signature/ESignature"),
    CurrencyInput: () =>
      import("../vue-weblineindia-currency-input/src/component"),
  },
  props: ["configDocId", "employeeDocId", "documentUsers", "employeeDocument"],
  warningMsg: "",
  computed: {
    ...mapGetters("auth", [
      "getAuthenticatedUser",
      "getInitialUrl",
      "getUserSignature",
      "getDefaultDateFormat",
      "getActiveWorkspace",
      "getUserType",
    ]),
    ...mapGetters("s3FileUpload", ["getFileUploadData", "getFileUploadStatus"]),
    ...mapGetters("navigationOpen", ["getIsMobile", "getIsRSOpen"]),
    ...mapGetters("employeeData", ["getESignData", "getESignVerifiedData"]),
    ...mapGetters("templatesData", [
      "getTemplatesFieldsCKData",
      "getUploadedFileData",
      "getRepeatableTemplateIds",
      "getFormbuilderTemplatesDataUpdateStatus",
      "getFormbuilderTemplatesDataUpdateData",
      "getDownloadUrl",
    ]),
    ...mapGetters("entities", ["getAllEntities"]),
    ...mapGetters("companyTemplates", ["getAllCompanyTemplatesData"]),
    ...mapGetters("configurableDocuments", ["getConfigurableDocumentDataById"]),
    currentAuthUserEmail() {
      return this.getAuthenticatedUser.email.toLowerCase();
    },
    totalFieldsCount() {
      return this.mappedFieldsOfUsers.length;
    },
    filledFieldsCount() {
      return this.editorFields.filter((item) => this.isFieldFilled(item))
        .length;
    },
    progressPercentage() {
      let percentage = (this.filledFieldsCount / this.totalFieldsCount) * 100;
      if (isNaN(percentage) || percentage < 0) {
        percentage = 0;
      } else if (percentage > 100) {
        percentage = 100;
      }
      return percentage;
    },
    getCurrentActiveUserAndDocumentsUsers() {
      let userValue = [];
      if (this.docUsers && this.docUsers.length) {
        this.docUsers.forEach((e) => {
          console.log(
            "this.employeeDoc.enforce_signature_order",
            this.employeeDoc.enforce_signature_order
          );
          if (this.employeeDoc.enforce_signature_order === true) {
            if (
              e &&
              e.email === this.employeeDoc.current_active_user &&
              !e.document_filling_status
            ) {
              let fullName = "";
              if (e.first_name) fullName += e.first_name;
              if (e.last_name) fullName += " " + e.last_name;
              userValue.push(fullName.trim());
            }
          } else {
            if (
              e &&
              e.email === this.getAuthenticatedUser.email && // Ensure this is the correct way to access the email
              !e.document_filling_status
            ) {
              let fullName = "";
              if (e.first_name) fullName += e.first_name;
              if (e.last_name) fullName += " " + e.last_name;
              userValue.push(fullName.trim());
            }
          }
        });
      }
      return userValue;
    },
    getCurrentActiveUser() {
      return (this.docUsers || []).filter((e) => {
        if (this.employeeDoc.enforce_signature_order) {
          if (
            e &&
            e.email === this.employeeDoc.current_active_user &&
            !e.document_filling_status
          ) {
            return true;
          }
        } else {
          if (
            e &&
            e.email === this.getAuthenticatedUser.email &&
            !e.document_filling_status
          ) {
            return true;
          }
        }
      });
    },
  },
  data() {
    return {
      ck_check_image:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAAb0lEQVRIie2SwQ2AIBAEpwo0tgjlGtFq9AEJiBBiOH43yf1gud0FFOUHK2BninvgBpy0+ALsUfwENhUfFreE0lrkhfrO2Q+uc3FIHMCQrF+8rYtlXntEvNAyiqFYWuROpnxFCNseccQ2LzFxFKXOA8KgLcHpsapLAAAAAElFTkSuQmCC",
      originalEditorFields: [],
      form: {},
      secureEsign: true,
      selectedOptions: [],
      weekdays_options: [
        { name: "Monday", value: "MON" },
        { name: "Tuesday", value: "TUE" },
        { name: "Wednesday", value: "WED" },
        { name: "Thursday", value: "THU" },
        { name: "Friday", value: "FRI" },
        { name: "Saturday", value: "SAT" },
        { name: "Sunday", value: "SUN" },
      ],
      messageShown: false,
      showFinishAndApprove: false,
      outerVisible: false,
      imgData: "",
      image_url: "",
      fileUploading: false,
      profileData: null,
      upLoadEffect: false,
      buttondisale: true,
      imageUrlData: "",
      filledByFromDocument: "",
      fileData: null,
      signLoading: false,
      signatureIndex: -1,
      imageFieldIndex: -1,
      loading: false,
      searchedLabel: "",
      reason_for_reject: "",
      nonReqCount: [],
      reqCount: [],
      loadingText: "",
      docUsers: [],
      editorFields: [],
      fillingUserFields: true,
      checkAllUsersAssigned: [],
      filteredMapFilledByFieldLabels: [],
      changeFilledByModal: false,
      prevPercentage: "",
      filledBySelections: [],
      mapFilledByFieldLabels: [],
      checkIsUserDeleted: false,
      editorKey: 0,
      captureSignatureDialog: false,
      previewEditorContent: "",
      mappedFieldsOfUsers: [],
      fillingEditorFields: false,
      rejectUserDocumentModal: false,
      previewEditorContentModal: false,
      hasCkEditorEmptyFields: false,
      fillingFields: [],
      senderFields: [],
      filledFieldCount: 0,
      receiverFields: [],
      customConvertedFields: [],
      conId: "",
      empId: "",
      employeeDoc: {},
      isCurrentApproverWithNoFields: [],
    };
  },
  methods: {
    checkIsApproverAndHasNoFields() {
      this.isCurrentApproverWithNoFields = [];
      let user = this.getCurrentActiveUser[0];
      this.isCurrentApproverWithNoFields = this.documentUsers.filter((e) => {
        if (
          e &&
          e.has_approval_access &&
          e.sent_status &&
          !e.document_filling_status &&
          e.value === user.value
        ) {
          return e;
        }
      });
      if (
        this.isCurrentApproverWithNoFields &&
        this.isCurrentApproverWithNoFields.length
      ) {
        this.showCkPreview();
      } else {
        this.fillingEditorFields = true;
      }
    },
    handleTimeRange(item) {
      console.log("-=-=0", item);
      if (
        item &&
        (item.type === "TIME_RANGE" || item.input_type === "TIME_RANGE") &&
        (item.value?.length === 0 ||
          item.value === undefined ||
          item.value === null)
      ) {
        item.value = "";
        item.time_range = [];
      }
    },
    resetImgData() {
      this.imgData = null;
    },
    getMaxValue(data) {
      console.log(data);
      if (this.form && this.form[data.key].max_selection) {
        return this.form[data.key].max_selection;
      }
      return data.max_selection || undefined;
    },
    getTimeProperties(item) {
      if (item.time_range && item.step_time && item.time_range.length) {
        let properties = {};
        properties["start"] = moment(item.time_range[0]).format("HH:mm:ss");
        properties["end"] = moment(item.time_range[1]).format("HH:mm:ss");
        if (item.step_time == 60 || item.step_time == "60") {
          properties["step"] = "01:00:00";
        } else {
          properties["step"] = "00:" + item.step_time + ":00";
        }
        return properties;
      }
      return {
        start: "00:00:00",
        step: "00:30:00",
        end: "23:59:00",
      };
    },
    checkIsCurrentUser(item) {
      let user = this.getCurrentActiveUser[0];
      if (item && item.filled_by === user.value) {
        return true;
      } else {
        return false;
      }
    },
    checkReqFieldsFilled(item) {
      let user = this.getCurrentActiveUser[0];
      this.nonReqCount = this.editorFields.filter(
        (e) =>
          e &&
          e.filled_by === user.value &&
          (e.is_required === false || e.is_required === "") &&
          (Array.isArray(e.value)
            ? e.value.length === 0
            : e.value === "" || e.value === null || e.value === undefined)
      );
      this.reqCount = this.editorFields.filter(
        (e) =>
          e &&
          e.filled_by === user.value &&
          e.is_required === true &&
          (Array.isArray(e.value)
            ? e.value.length === 0
            : e.value === "" || e.value === null || e.value === undefined)
      );
      if (
        item &&
        item.input_type === "PRIMARY_FIELDS@phone" &&
        item.value &&
        item.value.length &&
        (item.value.length > 10 || item.value.length < 10)
      ) {
        this.form[item.key] = "";
        this.$message.error("Invalid Phone Number");
      }
    },
    handleCurrencyUpdated(item) {
      const editorItemIndex = this.editorFields.findIndex(
        (e) => e.key === item.key && e.filled_by === item.filled_by
      );
      if (editorItemIndex !== -1) {
        this.$set(this.editorFields, editorItemIndex, {
          ...item,
          value: item.value,
        });
      }
    },
    isInvalidPhoneNumber(value) {
      return value.length !== 10;
    },
    isEmpty(item) {
      let user = this.getCurrentActiveUser[0];
      if (item.is_required && item.filled_by === user.value) {
        return !item.value;
      }
    },
    getCurrentAuthUser() {
      const lowercaseEmail = this.getAuthenticatedUser.email.toLowerCase();
      let currentUser = this.documentUsers.find((user) => {
        return user && user.email.toLowerCase() === lowercaseEmail;
      });
      return currentUser;
    },
    mapDateWithTz(value, offset, userFormat) {
      if (offset < 0) {
        value = moment(value).subtract(offset, "minutes").toISOString();
      } else {
        value = moment(value).add(offset, "minutes").toISOString();
      }
      let date = moment(value).format(userFormat);
      return date;
    },
    async updateDocumentSignature() {
      try {
        this.signLoading = true;
        this.loadingText = "Loading Signature...";
        await this.$store.dispatch("auth/fetchUserSignature");
        this.captureSignatureDialog = false;
        this.signLoading = false;
        let userSignature = null;
        let user = this.getCurrentActiveUser[0];
        if (
          user &&
          this.getAuthenticatedUser &&
          user.email === this.getAuthenticatedUser.email &&
          this.getAuthenticatedUser.signature
        ) {
          userSignature = this.getAuthenticatedUser.signature;
        } else {
          userSignature =
            this.getUserSignature && this.getUserSignature.signature;
        }
        this.form[this.editorFields[this.signatureIndex].key] = userSignature;
        this.$set(
          this.editorFields[this.signatureIndex],
          "value",
          userSignature
        );
        this.editorFields.forEach((field, index) => {
          if (
            field &&
            field.input_type === "SIGNATURE" &&
            field.key.startsWith(`${field.filled_by}`)
          ) {
            this.$set(
              this.editorFields[index],
              "value",
              this.form[this.editorFields[this.signatureIndex].key]
            );
          }
        });
        const dateValue = userSignature
          ? new Date().toISOString().split("T")[0]
          : "";
        this.editorFields.forEach((field, index) => {
          if (
            field.input_type === "DATE_SIGNED" &&
            field.filled_by === user.value
          ) {
            this.$set(this.editorFields[index], "value", dateValue);
            this.form[this.editorFields[index].key] = dateValue;
          }
        });
      } catch (err) {
        console.log("updateDocumentSignature", err);
      }
    },
    removeHashesFromKey(str) {
      return str.replace(/#/g, "");
    },
    rejectDoc() {
      this.rejectUserDocumentModal = false;
      this.$emit(
        "decline-document",
        this.reason_for_reject,
        this.editorFields,
        this.editorContent
      );
    },
    checkRejectAccess() {
      if (
        this.employeeDoc &&
        this.employeeDoc.document_approval_status != "REJECTED"
      ) {
        let currentUser = this.docUsers.find((user) => {
          return (
            user &&
            user.email.toLowerCase() ===
              this.getAuthenticatedUser.email.toLowerCase()
          );
        });
        if (
          currentUser &&
          currentUser.has_approval_access &&
          !currentUser.approval_status
        ) {
          return true;
        }
      }
      return false;
    },
    checkApproveAccess() {
      if (this.employeeDoc && this.employeeDoc.document_status != "REJECTED") {
        let currentUser =
          this.employeeDoc &&
          this.docUsers &&
          this.docUsers.find((user) => {
            return (
              user &&
              user.email.toLowerCase() ===
                this.getAuthenticatedUser.email.toLowerCase()
            );
          });
        if (
          currentUser &&
          currentUser.has_approval_access &&
          currentUser.approval_status != "APPROVED"
        ) {
          return true;
        }
      }
      return false;
    },
    isTextInputType(item) {
      const textInputTypes = [
        "SINGLE_LINE_TEXT",
        "MULTI_LINE_TEXT",
        "SINGLE_LINE_CONTENT",
      ];
      const excludedPrimaryFields = ["email"];
      const templateKeyParts =
        item && item.template_key && item.template_key.split("@");
      const fieldType = templateKeyParts && templateKeyParts[1];
      return (
        textInputTypes.includes(item.input_type) ||
        (item.input_type.startsWith("PRIMARY_FIELDS") &&
          !excludedPrimaryFields.includes(fieldType))
      );
    },
    getFieldType(item) {
      const templateKeyParts =
        item && item.template_key && item.template_key.split("@");
      const fieldType = templateKeyParts && templateKeyParts[1];

      if (fieldType === "phone") {
        return true;
      } else {
        return false;
      }
    },
    getFieldTypeIsEmail(item) {
      const templateKeyParts =
        item && item.template_key && item.template_key.split("@");
      const fieldType = templateKeyParts && templateKeyParts[1];
      if (fieldType === "email") {
        return true;
      } else {
        return false;
      }
    },
    filterLabels(searchTerm) {
      let user = this.getCurrentActiveUser[0];
      if (searchTerm && searchTerm.length) {
        const lowerSearchTerm = searchTerm.toLowerCase();
        this.editorFields = this.originalEditorFields.filter(
          (item) =>
            item.filled_by === user.value &&
            item.label.toLowerCase().includes(lowerSearchTerm)
        );
        return this.editorFields;
      } else {
        this.editorFields = [...this.originalEditorFields];
        return this.editorFields;
      }
    },
    showFillingModal() {
      this.fillingEditorFields = true;
      this.previewEditorContentModal = false;
      console.log("-=-=", this.reqCount);
    },
    closeFillingEditorFields() {
      this.fillingEditorFields = false;
      this.previewEditorContentModal = false;
      this.hasCkEditorEmptyFields = false;
      this.changeFilledByModal = false;
    },
    showCkPreview() {
      this.loading = true;
      this.loadingText = "Loading Editor Preview...";
      this.searchedLabel = "";
      this.editorFields = [...this.originalEditorFields];
      this.previewEditorContentModal = true;
      this.fillingEditorFields = false;
      this.previewEditorContent = this.editorContent;
      this.mapCkEditorDataUserSide();
      this.loading = false;
    },
    emitFinishEvent() {
      this.$emit("finish-document", this.editorFields, this.editorContent);
    },
    async emitApproveEvent() {
      this.loading = true; // Start the loading spinner
      try {
        await this.$emit(
          "approve-document",
          this.editorFields,
          this.editorContent
        );
        this.previewEditorContentModal = false;
        this.loadingText = "Please Wait Document Approval is in progress...";
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      } catch (error) {
        console.error("Document approval failed", error);
      } finally {
        this.loading = false; // Stop the loading spinner after completion
      }
    },
    async replaceVariablesInUserSide() {
      this.loading = true;
      this.loadingText = "Please Wait We Are Fetching Your Data...";
      this.hasCkEditorEmptyFields = false;
      let modifiedContents = [];
      const variableRegex = /\[\[(.*?)\]\]/g;
      // let dateOffset =
      //   this.getSingleDocumentData &&
      //   this.getSingleDocumentData.data &&
      //   this.getSingleDocumentData.data.senderTimeZoneFormatOffset;
      // let newOffset = dateOffset * -1;
      let modifiedContent = this.editorContent;
      const matches = this.editorContent.match(/\[\[(.*?)\]\]/g);
      const labels = matches
        ? matches.map((label) => this.normalizeLabel(label.slice(2, -2)))
        : [];
      const labelCountMap = {};
      labels.forEach((label) => {
        if (labelCountMap[label]) {
          labelCountMap[label] += 1;
        } else {
          labelCountMap[label] = 1;
        }
      });

      // Normalize labels in editorFields and initialize filteredEditorFields
      const filteredEditorFields = [];
      this.editorFields.forEach((field) => {
        const normalizedFieldLabel = this.normalizeLabel(field.label);
        if (labelCountMap[normalizedFieldLabel] > 0) {
          for (let i = 0; i < labelCountMap[normalizedFieldLabel]; i++) {
            filteredEditorFields.push(field);
          }
          labelCountMap[normalizedFieldLabel] = 0; // Reset the count to 0 after processing
        }
      });
      this.editorFields = filteredEditorFields;
      modifiedContent = modifiedContent.replace(
        variableRegex,
        (match, variableName) => {
          const matchingField = this.editorFields.find(
            (field) => field.label === variableName
          );
          if (matchingField) {
            if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              (matchingField.type === "SIGNATURE" ||
                matchingField.input_type === "SIGNATURE")
            ) {
              if (
                this.isBase64String(matchingField.value) &&
                !matchingField.value.includes("<img")
              ) {
                let val = matchingField.value;
                let imageHtml = `<img src="${val}" style="margin-bottom:-20px;margin-left:-10px;" width="${matchingField.width}px" height="${matchingField.height}px" alt="${variableName}"/>`;
                return imageHtml;
              } else if (
                !this.isBase64String(matchingField.value) &&
                !matchingField.value.includes("<img")
              ) {
                let imageHtml = `<img src="${matchingField.value}" style="margin-bottom:-20px;margin-left:-10px;" width="${matchingField.width}px" height="${matchingField.height}px" alt="${variableName}"/>`;
                return imageHtml;
              } else if (matchingField.value.includes("<img")) {
                return matchingField.value;
              }
            }
            if (
              matchingField.value &&
              (matchingField.type === "IMAGE" ||
                matchingField.input_type === "IMAGE")
            ) {
              console.log("Image URL:", matchingField.value);
              let imageHtml = `<img src="${matchingField.value}" width="150px" height="150px" alt="${variableName}"/>`;
              return imageHtml;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "DATE_TIME" ||
                matchingField.input_type === "DATE_TIME")
            ) {
              const format = "MM-DD-YYYY HH:mm:ss";
              // let dt = this.mapDateWithTz(
              //   matchingField.value,
              //   newOffset,
              //   format
              // );
              let dt = moment(matchingField.value).format(format);
              //moment(matchingField.value).format(format);
              return `<span style="color: #000000;">${dt}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "DATE_TIME_RANGE" ||
                matchingField.input_type === "DATE_TIME_RANGE")
            ) {
              const format = "MM-DD-YYYY HH:mm:ss";
              let newAr = [];
              for (var i = 0; i < matchingField.value.length; i++) {
                let obj = moment(matchingField.value[i]).format(format);
                newAr.push(obj);
              }
              let contentValue = newAr.join(" To ");
              return `<span style="color: #000000;">${contentValue}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "DATE_RANGE" ||
                matchingField.input_type === "DATE_RANGE")
            ) {
              const format = "MM-DD-YYYY ";
              let newAr = [];
              for (i = 0; i < matchingField.value.length; i++) {
                let obj = moment(matchingField.value[i]).format(format);
                newAr.push(obj);
              }
              let contentValue = newAr.join(" To ");
              return `<span style="color: #000000;">${contentValue}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "TIME_RANGE" ||
                matchingField.input_type === "TIME_RANGE")
            ) {
              const format = " HH:mm:ss";
              let newAr = [];
              for (i = 0; i < matchingField.value.length; i++) {
                let obj = moment(matchingField.value[i]).format(format);
                newAr.push(obj);
              }
              let contentValue = newAr.join(" To ");
              return `<span style="color: #000000;">${contentValue}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              !matchingField.is_fixed &&
              (matchingField.type === "TIME" ||
                matchingField.input_type === "TIME")
            ) {
              let format = " HH:mm:ss";
              let dt = moment(matchingField.value).format(format);
              let val = dt ? dt : "";
              return `<span style="color: #000000;">${val}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              matchingField.is_fixed &&
              (matchingField.type === "TIME" ||
                matchingField.input_type === "TIME")
            ) {
              let val = matchingField.value ? matchingField.value : "";
              return `<span style="color: #000000;">${val}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "WEEKDAYS" ||
                matchingField.input_type === "WEEKDAYS")
            ) {
              if (
                matchingField.value &&
                (matchingField.allow_multiple === "true" ||
                  matchingField.allow_multiple === true)
              ) {
                const matchingNames = this.weekdays_options
                  .filter((option) =>
                    matchingField.value.includes(option.value)
                  )
                  .map((option) => option.name);

                let contentValue = matchingNames.join(",");
                return `<span style="color: #000000;">${contentValue}</span>`;
              } else {
                if (matchingField.value) {
                  let val = this.weekdays_options.find(
                    (e) => e.value === matchingField.value
                  );
                  return `<span style="color: #000000;">${val.name}</span>`;
                } else {
                  return "";
                }
              }
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "DATE" ||
                matchingField.input_type === "DATE")
            ) {
              const format = "MM-DD-YYYY";
              let dt = moment(matchingField.value).format(format); //moment(matchingField.value).format(format);
              let val = dt ? dt : "";
              return `<span style="color: #000000;">${val}</span>`;
            } else if (
              matchingField.value &&
              matchingField.value !== null &&
              matchingField.value !== "" &&
              matchingField.value !== undefined &&
              (matchingField.type === "DATE_SIGNED" ||
                matchingField.input_type === "DATE_SIGNED")
            ) {
              const format = "MM-DD-YYYY";
              let dt = moment(matchingField.value).format(format); //moment(matchingField.value).format(format);
              let val = dt ? dt : "";
              return `<span style="color: #000000;">${val}</span>`;
            } else if (
              matchingField.value === "" ||
              matchingField.value === null ||
              matchingField.value === undefined ||
              matchingField.value === "null" ||
              matchingField.value === "undefined"
            ) {
              if (matchingField.input_type.startsWith("PRIMARY_FIELDS")) {
                this.mappingPrimaryFieldsData(matchingField);
              }
            } else if (
              matchingField.value !== null ||
              matchingField.value !== undefined ||
              matchingField.value !== ""
            ) {
              return matchingField.value;
            }
          }
          return match;
        }
      );
      modifiedContents.push(modifiedContent);
      console.log("modifiedContents", modifiedContents);
      this.loading = false;
      return modifiedContents;
    },
    async mapCkEditorDataUserSide() {
      this.loading = true;
      this.loadingText = "Mapping fields data into editor...";
      try {
        this.showFinishAndApprove = false;
        let user = this.getCurrentActiveUser[0];
        this.loading = true;
        this.loadingText = "Mapping fields data into editor...";
        this.previewEditorContentModal = true;
        this.fillingEditorFields = false;
        this.editorFields.forEach((e) => {
          if (e && e.filled_by === user.value) {
            e.value = this.form[e.key];
          }
        });
        let dtatat;
        if (
          (this.$route &&
            this.$route.name &&
            this.$route.name === "employee-documents-custom-document") ||
          this.$route.name === "employee-documents-custom-document-pp"
        ) {
          dtatat = await this.replaceVariablesInUserSide();
          this.previewEditorContentModal = true;
        }
        this.previewEditorContent = dtatat[dtatat.length - 1];
        this.senderFields = [];
        this.hasCkEditorEmptyFields = false;
        this.loading = false;
        if (this.progressPercentage === 100) {
          this.showFinishAndApprove = true;
        }
      } catch (err) {
        console.log(err);
      }
    },
    getFormat() {
      let format = "";
      if (this.totalFieldsCount) {
        format = this.filledFieldsCount + "/" + this.totalFieldsCount;
      } else {
        format = "0/0";
      }
      return format;
    },
    async setEntityDataToField() {
      let da;
      let cType;
      let user = this.getCurrentActiveUser[0];
      let eDataIds = this.documentUsers
        .filter((e) => e.entity_data_id)
        .map((e) =>
          typeof e.entity_data_id === "string"
            ? e.entity_data_id
            : e.entity_data_id._id
        );
      if (this.getCurrentActiveUser[0]) {
        if (user && user.contact_type && user.contact_type._id) {
          cType = user.contact_type._id;
        } else if (
          user &&
          user.contact_type &&
          typeof user.contact_type === "string"
        ) {
          cType = user.contact_type;
        } else if (
          user &&
          user.entity_data_id &&
          user.entity_data_id.entity_id
        ) {
          cType = user.entity_data_id.entity_id;
        } else {
          cType = user.entity_data_id ? user.entity_data_id : user.contact_type;
        }
      }
      // this.loading = true;
      this.loadingText =
        "Mapping mentioned fields with user entity fields data...";
      if (cType) {
        this.allMentionableFields = await this.fetchEntityDetails(cType, true);
      }
      if (eDataIds && eDataIds.length) {
        let params = {
          entity_data_ids: eDataIds,
        };
        let response = await postAPICall("GET", "/templates-data", params);
        this.getTemplatesData = response.data || response.data.data;
      }
      this.loading = false;
      this.editorFields.forEach((editorField) => {
        if (
          !(this.allMentionableFields || []).find(
            (e) => e.template_key === editorField.template_key
          )
        ) {
          if (!Array.isArray(this.allMentionableFields)) {
            this.allMentionableFields = [];
          }
          this.allMentionableFields.push(editorField);
        }
      });
      this.allSelectedEntityMentionFields = this.allMentionableFields;
      let seletedUserTemplateData = this.getTemplatesData.filter(
        (e) =>
          (user.entity_data_id &&
            user.entity_data_id._id &&
            e.entity_data_id == user.entity_data_id._id) ||
          e.entity_data_id == user.entity_data_id
      );
      da = seletedUserTemplateData.filter((e) =>
        this.allMentionableFields.some((el) => el.template_id === e.template_id)
      );
      console.log(da);
      this.editorFields = this.editorFields.map((field) => {
        const entity = da.find((e) => e.template_id === field.template_id);
        console.log(entity, "llll");
        if (
          entity &&
          entity.template_data[field.key] !== undefined &&
          (field.filled_by === user.value ||
            (entity?.template_id === field?.template_id &&
              field?.selected_user !== field?.filled_by))
        ) {
          let newValue = entity.template_data[field.key];
          if (
            field &&
            field.input_type &&
            field.input_type === "CHECKBOX_GROUP" &&
            newValue === false
          ) {
            newValue = [];
          }
          this.form[field.key] = field.value ? field.value : newValue;
          return { ...field, value: field.value ? field.value : newValue };
        }
        return field;
      });
    },
    normalizeLabel(label) {
      return label.trim().replace(/\s+/g, "_");
    },
    async setEntityDataToFieldForCk() {
      try {
        this.loading = true;
        this.loadingText =
          "Mapping mentioned fields with user entity fields data...";
        let da;
        let cType;
        let userVal = this.documentRecipientsList
          ? this.documentRecipientsList
          : this.documentUsers;
        let selectedUserTemplateData = [];
        let eDataIds = userVal
          .filter((e) => e.entity_data_id)
          .map((e) =>
            typeof e.entity_data_id === "string"
              ? e.entity_data_id
              : e.entity_data_id._id
          );
        for (const user of userVal) {
          if (user) {
            if (user.contact_type && user.contact_type._id) {
              cType = user.contact_type._id;
            } else if (typeof user.contact_type === "string") {
              cType = user.contact_type;
            } else if (user.entity_data_id && user.entity_data_id.entity_id) {
              cType = user.entity_data_id.entity_id;
            } else {
              cType = user.entity_data_id || user.contact_type;
            }
            if (cType) {
              this.allMentionableFields = await this.fetchEntityDetails(
                cType,
                true
              );
            }
            if (eDataIds && eDataIds.length) {
              let params = {
                entity_data_ids: eDataIds,
              };
              let response = await postAPICall(
                "GET",
                "/templates-data",
                params
              );
              this.getTemplatesData = response.data || response.data.data;
            }
            this.loading = false;

            this.editorFields.forEach((editorField) => {
              if (
                !(this.allMentionableFields || []).find(
                  (e) => e.template_key === editorField.template_key
                )
              ) {
                if (!Array.isArray(this.allMentionableFields)) {
                  this.allMentionableFields = [];
                }
                this.allMentionableFields.push(editorField);
              }
            });
            this.allSelectedEntityMentionFields = this.allMentionableFields;
            selectedUserTemplateData = this.getTemplatesData.filter(
              (e) =>
                (user.entity_data_id &&
                  user.entity_data_id._id &&
                  e.entity_data_id === user.entity_data_id._id) ||
                e.entity_data_id === user.entity_data_id
            );

            da = selectedUserTemplateData.filter((e) =>
              this.allMentionableFields.some(
                (el) => el.template_id === e.template_id
              )
            );

            this.editorFields = this.editorFields.map((field) => {
              const entity = da.find(
                (e) => e.template_id === field.template_id
              );
              console.log(entity.template_data[field.key]);
              if (
                entity &&
                entity.template_data[field.key] !== undefined &&
                (field.filled_by === user.value ||
                  (entity?.template_id === field?.template_id &&
                    field?.selected_user !== field?.filled_by))
              ) {
                let newValue = entity.template_data[field.key];
                if (
                  field.input_type === "CHECKBOX_GROUP" &&
                  newValue === false
                ) {
                  newValue = [];
                }
                this.form[field.key] = newValue || field.value;
                return { ...field, value: newValue || field.value };
              }
              return field;
            });
            this.loading = false;
          }
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchConfigurableDocument() {
      await this.$store.dispatch(
        "configurableDocuments/fetchConfigurableDocumentById",
        {
          id: this.conId,
        }
      );
      let userVal = this.checkCurrentActiveUserAndDocumentsUsers();
      await this.fetchEntities();
      this.secureEsign =
        this.employeeDoc.document_settings &&
        this.employeeDoc.document_settings.secure_e_sign &&
        this.employeeDoc.document_settings.secure_e_sign
          .allow_document_e_sign &&
        this.employeeDoc.document_settings.secure_e_sign
          .allow_document_e_sign === true
          ? true
          : false;
      console.log("this.secureEsign", this.secureEsign);
      this.editorFields = this.employeeDoc.configurable_document_data
        .ckeditor_fields
        ? this.employeeDoc.configurable_document_data.ckeditor_fields
        : this.getConfigurableDocumentDataById.ckeditor_fields;
      this.editorContent =
        this.employeeDoc.configurable_document_data
          .ck_editor_unmapped_content &&
        this.employeeDoc.configurable_document_data
          .ck_editor_unmapped_content !== ""
          ? this.employeeDoc.configurable_document_data
              .ck_editor_unmapped_content
          : this.employeeDoc.configurable_document_data.ck_editor_content;
      const matches = this.editorContent.match(/\[\[(.*?)\]\]/g);
      const labels = matches
        ? matches.map((label) => this.normalizeLabel(label.slice(2, -2)))
        : [];
      const labelCountMap = {};
      labels.forEach((label) => {
        if (labelCountMap[label]) {
          labelCountMap[label] += 1;
        } else {
          labelCountMap[label] = 1;
        }
      });

      // Normalize labels in editorFields and initialize filteredEditorFields
      const filteredEditorFields = [];
      this.editorFields.forEach((field) => {
        const normalizedFieldLabel = this.normalizeLabel(field.label);
        if (labelCountMap[normalizedFieldLabel] > 0) {
          for (let i = 0; i < labelCountMap[normalizedFieldLabel]; i++) {
            filteredEditorFields.push(field);
          }
          labelCountMap[normalizedFieldLabel] = 0; // Reset the count to 0 after processing
        }
      });
      this.editorFields = filteredEditorFields;
      this.form = this.editorFields.reduce((acc, field) => {
        if (field.input_type === "CURRENCY" && field.value === "") {
          field.value = 0;
        } else if (
          (field.input_type === "CHECKBOX_GROUP" ||
            field.input_type === "DATE_RANGE" ||
            field.input_type === "DATE_TIME_RANGE" ||
            field.input_type === "TIME_RANGE" ||
            field.input_type === "MULTI_SELECT") &&
          (field.value === undefined ||
            field.value === "" ||
            field.value === null)
        ) {
          field.value = [];
        } else if (
          field.input_type === "TIME_RANGE" &&
          field.value.length === 0
        ) {
          field.value = null;
        }
        acc[field.key] = field.value;
        return acc;
      }, {});
      this.loading = true;
      await this.fillSignatureDateSigned();
      await this.checkPrimaryFieldsAndAddData();
      this.loading = false;
      this.mappedFieldsOfUsers =
        (this.employeeDoc &&
          this.employeeDoc.configurable_document_data &&
          Array.isArray(this.editorFields) &&
          this.editorFields.filter(
            (e) =>
              e.filled_by === userVal &&
              e.input_type !== "PARAGRAPH" &&
              e.input_type !== "HORIZONTAL_LINE"
          )) ||
        [];
      if (this.employeeDoc && this.employeeDoc.document_settings) {
        // if (this.employeeDoc.document_settings.update_entity_data === 0) {
        //   await this.setEntityDataToField();
        // }
        // else if (
        //   this.getCurrentActiveUser[0] &&
        //   this.getCurrentActiveUser[0].entity_data_id &&
        //   typeof this.getCurrentActiveUser[0].entity_data_id === "object" &&
        //   this.employeeDoc.document_settings.update_entity_data === 1
        // ) {
        //   await this.setEntityDataToFieldForCk();
        // }
      }

      await this.checkIsCheckBoxGroup();
      this.originalEditorFields = [...this.editorFields];
      this.checkReqFieldsFilled();
    },

    checkIsCheckBoxGroup() {
      this.mappedFieldsOfUsers.forEach((field) => {
        if (
          (field && field.input_type === "HEADING") ||
          field.input_type === "PARAGRAPH"
        ) {
          if (
            field &&
            (field.value === undefined || this.form[field.key] === undefined)
          ) {
            this.$set(field, "value", "");
            this.form[field.key] = "";
          } else {
            let val =
              field.value !== ""
                ? field.value
                : this.replaceUnderscoresWithSpaces(field.key);
            this.$set(this.form[field.key], "value", val);
            this.$set(field, "value", val);
          }
        }
        if (
          (field.value === undefined ||
            field.value === "" ||
            this.form[field.key] === false ||
            field.value === false) &&
          (field.input_type === "CHECKBOX_GROUP" ||
            field.input_type === "DATE_RANGE" ||
            field.input_type === "DATE_TIME_RANGE" ||
            field.input_type === "TIME_RANGE" ||
            field.input_type === "MULTI_SELECT")
        ) {
          this.$set(field, "value", []);
        } else if (
          field.input_type === "TIME_RANGE" &&
          Array.isArray(field.value)
        ) {
          field.value = "";
          field.time_range = [];
        } else if (
          field.input_type === "CURRENCY" &&
          (field.value === undefined ||
            field.value === "" ||
            field.value === null)
        ) {
          this.$set(field, "value", 0);
        } else {
          if (field.value === undefined || field.value === null) {
            this.$set(field, "value", "");
          }
        }
      });
    },
    async fillSignatureDateSigned() {
      const currentUser =
        this.getCurrentActiveUser &&
        this.getCurrentActiveUser.length &&
        this.getCurrentActiveUser[0].value;
      const signatureFieldsWithValue = this.editorFields.filter(
        (e) =>
          (e.input_type === "SIGNATURE" || e.type === "SIGNATURE") &&
          e.value &&
          e.value.length &&
          e.filled_by === currentUser
      );
      this.editorFields.forEach((matchingField) => {
        if (!matchingField.template_key) {
          return;
        }
        const isDateSignedField =
          matchingField.input_type === "DATE_SIGNED" ||
          matchingField.type === "DATE_SIGNED";
        // const isFieldFilledByCurrentUser =
        //   matchingField.filled_by === currentUser;
        if (
          signatureFieldsWithValue.length &&
          isDateSignedField &&
          matchingField.filled_by === currentUser &&
          (matchingField.value === null ||
            matchingField.value === "" ||
            matchingField.value === undefined)
        ) {
          let date = new Date();
          matchingField.value = date;
        }
      });
    },
    async checkPrimaryFieldsAndAddData() {
      this.loading = true;
      this.loadingText = "Fetching Data...";
      if (!this.editorFields || !Array.isArray(this.editorFields)) {
        console.error("editorFields is not defined or not an array");
        return;
      }

      const [user] = this.getCurrentActiveUser;
      if (!user) {
        console.error("getCurrentActiveUser does not contain a valid user");
        return;
      }

      const {
        first_name,
        last_name,
        address,
        phone,
        company_name,
        email,
        title,
      } = user;

      this.editorFields.forEach((matchingField) => {
        if (!matchingField.template_key) {
          return;
        }
        let [userVal] = this.getCurrentActiveUser;
        const templateKeyParts = matchingField.template_key.split("@");
        const fieldType = templateKeyParts[1];
        if (matchingField.filled_by === userVal.value) {
          switch (fieldType) {
            case "first_name":
              this.form[matchingField.key] = matchingField.value =
                matchingField.value || first_name;
              break;
            case "last_name":
              this.form[matchingField.key] = matchingField.value =
                matchingField.value || last_name;
              break;
            case "address":
              this.form[matchingField.key] = matchingField.value =
                matchingField.value || address;
              break;
            case "phone":
              this.form[matchingField.key] = matchingField.value =
                matchingField.value || phone;
              break;
            case "company":
              this.form[matchingField.key] = matchingField.value =
                matchingField.value || company_name;
              break;
            case "email":
              this.form[matchingField.key] = matchingField.value =
                matchingField.value || email;
              break;
            case "full_name":
              this.form[matchingField.key] = matchingField.value =
                matchingField.value || `${first_name} ${last_name}`;
              break;
            case "title":
              this.form[matchingField.key] = matchingField.value =
                matchingField.value || title;
              break;
            default:
              console.warn(`Unhandled fieldType: ${fieldType}`);
          }
        }
      });
      this.loading = false;
    },
    isBase64String(value) {
      const regex = /^data:image\/[a-z]+;base64,/;
      return regex.test(value);
    },
    async captureSignature(item, index) {
      this.signLoading = true;
      this.loadingText = "Fetching Signature...";
      this.signatureIndex = index;
      this.captureSignatureDialog = true;
      this.signLoading = false;
    },
    async fetchEntities() {
      let params = {
        get_all: true,
      };
      await this.$store.dispatch("entities/fetchEntities", params);
      this.allEntities = [...this.getAllEntities.data];
    },
    checkCurrentActiveUserAndDocumentsUsers() {
      let userValue = "";
      if (this.docUsers && this.docUsers.length) {
        this.docUsers.forEach((e) => {
          if (this.employeeDoc.enforce_signature_order) {
            if (
              e &&
              e.email === this.employeeDoc.current_active_user &&
              !e.document_filling_status
            ) {
              userValue = e.value;
            }
          } else {
            if (
              e &&
              e.email === this.getAuthenticatedUser.email &&
              !e.document_filling_status
            ) {
              userValue = e.value;
            }
          }
        });
      }
      return userValue;
    },
    closeFillingUserFields() {
      this.fillingEditorFields = false;
      this.previewEditorContentModal = false;
      this.hasCkEditorEmptyFields = false;
      this.changeFilledByModal = false;
    },
    updateEditorFieldsFromForm() {
      let user = this.getCurrentActiveUser[0];
      this.editorFields.forEach((field) => {
        if (field && field.key && field.filled_by === user.value) {
          field.value = this.form[field.key];
        }
      });
    },
    isFieldFilled(field) {
      if (field && field.key) {
        field.value = this.form[field.key];
      }
      if (
        (field.value === undefined || field.value === null) &&
        [
          "DATE_RANGE",
          "DATE_TIME_RANGE",
          "TIME_RANGE",
          "MULTI_SELECT",
          "CHECKBOX_GROUP",
        ].includes(field.input_type)
      ) {
        this.$set(field, "value", []);
      } else if (field && field.value === undefined) {
        this.$set(field, "value", "");
      }

      let user = this.getCurrentActiveUser[0];

      if (
        field.value !== undefined &&
        Array.isArray(field.value) &&
        field.filled_by === user.value
      ) {
        return field.value.length > 0;
      }
      let hasSignatureField = this.editorFields.some(
        (e) =>
          (e.input_type === "SIGNATURE" || e.type === "SIGNATURE") &&
          e.filled_by === user.value
      );
      if (
        !hasSignatureField &&
        field.filled_by === user.value &&
        (field.input_type === "DATE_SIGNED" || field.type === "DATE_SIGNED")
      ) {
        return true;
      }
      return (
        field.filled_by === user.value &&
        field.value !== null &&
        field.value !== undefined &&
        field.value !== ""
      );
    },
    uploadImageOuterVisible(index) {
      this.outerVisible = true;
      this.imageFieldIndex = index;
    },
    closeImage() {
      this.outerVisible = false;
      this.uploadFile(this.fileData);
    },
    async uploadFile(file) {
      var files = await this.dataURLtoFile(file, "image1.jpeg");
      console.log("files", files);
      this.uploadImageFile(files);
    },
    async uploadImageFile(file) {
      this.loading = true;
      if (
        file.type == "image/jpeg" ||
        file.type == "image/png" ||
        file.type == "image/jpg"
      ) {
        var formData = new FormData();
        formData.append("files", file);
        await this.$store.dispatch("s3FileUpload/uploadFileToS3", formData);
        if (this.getFileUploadStatus && this.getFileUploadData) {
          this.loading = false;
          this.form[this.editorFields[this.imageFieldIndex].key] =
            this.getFileUploadData;
          this.editorFields.forEach((field) => {
            if (field && field.input_type === "IMAGE") {
              this.$set(
                field,
                "value",
                this.form[this.editorFields[this.imageFieldIndex].key]
              );
            }
          });
          await this.getBase64(file).then((res) => {
            var img = document.createElement("img");
            img.src = res;
            img.onload = function () {
              img.remove();
            };
          });
        } else {
          this.loading = false;
          this.$alert("Error while uploading file");
        }
      } else {
        this.loading = false;
        this.$alert("Please upload a image file");
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    setImageData(file) {
      this.fileData = null;
      this.fileData = file;
    },
    insertImage(file) {
      // this.fileData = file;
      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          this.fileData = reader.result;
        };
        reader.readAsDataURL(file);
      }
    },
    fileError() {
      this.$alert("File Supported:JPG,PNG,JPEG,Gif");
    },
  },
  watch: {
    searchedLabel: function (newVal) {
      this.filterLabels(newVal);
    },
    form: {
      handler() {
        this.editorFields.forEach((field) => {
          if (field && field.key) {
            this.isFieldFilled(field);
            this.updateEditorFieldsFromForm();
          }
        });
      },
      deep: true,
    },
    editorFields: {
      handler: function () {},
      deep: true,
      immediate: true,
    },
    progressPercentage(newVal) {
      if (
        newVal === 100 &&
        this.reqCount &&
        !this.reqCount.length &&
        this.nonReqCount &&
        !this.nonReqCount.length
      ) {
        // this.$message.success(
        //   "Congratulations! You have successfully completed all the required fields."
        // );
        this.messageShown = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.sidemenu {
  height: 440px;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
.sidemenu::-webkit-scrollbar {
  width: 1em;
  height: 0.5em;
}

.sidemenu::-webkit-scrollbar-horizontal {
  /* Select the down or left scroll button when it's being hovered by the mouse */
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #ffffff;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}

.sidemenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px #f5f5f5;
  background-color: #f5f5f5;
}

.sidemenu::-webkit-scrollbar-button:single-button {
  width: 10px;
  height: 13px;
  background-color: #bbbbbb;
  display: block;
  border-style: solid;
}

.sidemenu::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  border-color: transparent transparent #777777 transparent;
}

/* Down */
.sidemenu::-webkit-scrollbar-button:horizontal:single-button:increment:hover {
  border-color: #777777 transparent transparent transparent;
}

.same-width {
  width: 70%;
}
.field-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.label-column {
  margin-right: 2px;
}
.filledby-column {
  margin-left: 2px;
}
.separator-line {
  width: 100%;
  height: 1px;
  background-color: #dcdcdc;
  margin: 10px 0;
}
.custom-dialog {
  margin-top: 30px;
  width: 100%;
  height: 99%;
}
@keyframes finger-blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.input-error {
  position: relative;
}

.alertSty {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: left;
  float: left;
  margin-right: 180px;
  margin-top: 2px;
  color: #f06b3a;
}
.alertStyS {
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: left;
  float: left;
  margin-right: 180px;
  margin-top: 2px;
  color: rgb(19, 206, 102);
  font-weight: 500px;
}
.imgSty {
  width: 30px;
  height: 30px;
  transform: rotate(270deg);
  background-size: contain;
  background-repeat: no-repeat;
  animation: finger-blink 1s infinite;
  margin-right: 10px;
  float: left;
}

.buttonGroup {
  display: flex;
  gap: 10px;
  float: right;
}

.input-error::after {
  content: "";
  position: absolute;
  transform: translateY(-50%);
  width: 30px;
  top: -12px;
  right: 25px;
  height: 30px;
  background-image: url("../../assets/img/icons/Hand.svg");
  background-size: contain;
  background-repeat: no-repeat;
  animation: finger-blink 1s infinite;
}
/* .el-card {
  border: 1px solid #ebeef5;
  background-color: #fff;
  overflow: hidden;
  color: #303133;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  box-shadow: 0 0 10px 2px rgba(27, 72, 126, 0.1294117647) !important;
  border-radius: 14px;
} */
</style>
